import './App.css';
import Navbar from './components/Navbar/Navbar';
import {BrowserRouter,Route,Routes} from "react-router-dom"
import Home from './pages/Home';
import ShopCategory from './pages/ShopCategory';
import Product from "./pages/Product";
import Cart from "./pages/Cart"
import LoginSignup from "./pages/LoginSignup"
import Footer from './components/Footer/Footer';
import Trending from "./components/Assets/banner/trending.png"
import collection from "./components/Assets/banner/collection.png"
import sale from "./components/Assets/banner/sale.png"
import Announcements from './components/Announcements/Announcements';


import Confirmation from './pages/Confirmation';
import CashondeliveryPage from './pages/CashondeliveryPage';


//Navbar is available for all the pagesso its outside the routes

function App() {
  return (
    <div >
    <BrowserRouter>
  
      <Navbar/>
      <Announcements/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/trending" element={<ShopCategory banner={Trending} category="trending"/>}/>
        <Route path="/collection" element={<ShopCategory banner={collection}  category="collection"/>}/>
        <Route path="/sale" element={<ShopCategory banner={sale} category="sale"/>}/>
        <Route path="product" element={<Product/>}>
        <Route path=':productId' element={<Product/>}/>
        
        </Route>
        <Route path="/cart" element={<Cart/>}/>
       
        <Route path="/login" element={<LoginSignup/>}/>
        <Route exact path="/cash-on-delivery" element={<CashondeliveryPage/>} />
        <Route exact path="/Confirmation" element={<Confirmation/>} />
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
