import React from 'react'
// import Slider from '../components/slider/Slider'
import Popular from '../components/Popular/Popular'
import Offers from '../components/Offers/Offers'
import NewCollections from '../components/NewCollections/NewCollections'
import Sliderslick from '../components/slider/Slider'
import Reviews from '../components/Reviews/Reviews'



const Home = () => {
  return (
    <div>
  
     <Sliderslick/>
   
     <Popular/>
     <Offers/>
     <NewCollections/>
     <Reviews/>
    </div>
  )
}

export default Home
