import React, { useState } from 'react'
import "../Navbar/Navbar.css"

//import logo from "../Assets/logo.png"
////import Name from "../Assets/Name.png"
//import cart_icon from "../Assets/cart_icon.png"
import cart_icon from "../Assets/cart.png"
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { ShopContext } from '../../context/ShopContext'

const Navbar = () => {
    const[menu,setMenu] =useState("")
    const{getTotalCartItems}=useContext(ShopContext)
  return (
      
    <div className='navbar'>
   
    <div className='nav-logo'>
      
       
        <h1>Syeda's Collection</h1>
    </div>
      <ul className ="nav-menu">
        <li onClick={()=>{setMenu("home")}}><Link style={{textDecoration:"none", color:"black"}} to="/">Home</Link>{menu==="home"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("trending")}}><Link style={{textDecoration:"none",color:"black"}} to="/trending">Trending</Link>{menu==="trending"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("collection")}}><Link style={{textDecoration:"none",color:"black"}} to="/collection">Collection</Link>{menu==="collection"?<hr/>:<></>}</li>
        <li onClick={()=>{setMenu("sale")}}><Link style={{textDecoration:"none",color:"black"}} to="/sale">Sale</Link>{menu==="sale"?<hr/>:<></>}</li>
      </ul>
      <div className='nav-login-cart'>
      {localStorage.getItem('auth-token')?
      <button onClick={()=>{localStorage.removeItem('auth-token');window.location.replace('/')}}>Logout</button>:  <Link  style={{textDecoration:"none"}}to ="/login"><button>Login</button></Link>}
          
           <Link style={{textDecoration:"none"}} to="/cart"><img src={cart_icon} alt=""/></Link> 
           <div className="nav-cart-count">{getTotalCartItems()}</div>
      </div>
      
    </div>
   
  )
}

export default Navbar


