import React, { useState, useEffect } from 'react';
import '../slider/Slider.css';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import collage1 from '../Assets/bracelets/collage1.png';
import collage2 from '../Assets/bracelets/collage2.png';
import collage3 from '../Assets/bracelets/collage3.png';

const Sliderslick = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current image index in a cyclic manner
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 5000); // Change image every 1 second

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Run only once when the component mounts

  return (
    <div className='slider'>
      {/* Display images based on the currentImageIndex */}
      {currentImageIndex === 0 && <img src={collage1} alt='' />}
      {currentImageIndex === 1 && <img src={collage2} alt='' />}
      {currentImageIndex === 2 && <img src={collage3} alt='' />}
    </div>
  );
};

export default Sliderslick;

