


import React, { useContext,useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ShopContext } from '../context/ShopContext';
import "./Css/CashOnDeliveryPage.css"

const CashondeliveryPage = () => {
    const {all_product, cartItems, getTotalCartAmount,clearCart} = useContext(ShopContext);
    const navigate = useNavigate(); 
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [data, setData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        street:"",
     city: '',
       phone:'',
    });
    const onChangeHandler = (e) => {
       const name=e.target.name;
       const value=e.target.value;
       setData({...data, [name]: value});
    };
    const handleClearCart = () => {
        clearCart(); // Clear cart items
    };
    const cashOnDelivery = async (event) => {
        event.preventDefault();
       
      
       const orderItems = all_product.filter(item => cartItems[item.id] > 0)
               .map(item => ({
                   _id: item.id,
                   name: item.name,
                   price: item.new_price,
                   quantity: cartItems[item.id],
                   image:item.image,
                   
               }));
       console.log(orderItems); 
      
        let orderData={
            
            items:orderItems,
            amount:getTotalCartAmount() + 200,
            address:data
        }
        // let response = await fetch('http://localhost:4000/cash-on-delivery', {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(orderData),
        //   });
        //   if (response.status === 200) {
        //     alert("Order Placed Successfully");
        //   } else {
        //     alert("Something went wrong");
        //   }
      
        try {
                        const token = localStorage.getItem('auth-token');
                        const response = await fetch('http://localhost:4000/cash-on-delivery', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'auth-token': token,
                            },
                            body: JSON.stringify(orderData),
                        });
                
                        const responseData = await response.json();
                
                        if (responseData.success) {
                            // Handle success
                            localStorage.setItem('auth-token', responseData.token);
                            window.location.replace('/');
                            
                        } else {
                            // Handle errors
                            alert(responseData.message);
                        }
                    } catch (error) {
                        console.error('Error:', error.message);
                        // Handle error (display error message, etc.)
                        alert('Failed to place the order');
                    }
                };
                
                
                    useEffect(() => {
        const token = localStorage.getItem('auth-token');
        if (!token) {
            alert("To place an order, please sign in first.");
            navigate('/cart');
        } else if (getTotalCartAmount() === 0) {
            navigate('/cart');
        }
    }, [getTotalCartAmount, navigate]);
          
                
    const handleCancel = () => {
        setData({
            firstName: '',
            lastName: '',
            email: '',
            street: '',
            city: '',
            phone: '',
        });
    };  
   
    const handleConfirmOrder = async () => {
        try {
            // Call clearCart to empty the cart
            clearCart();

            // Navigate to the confirmation page
            navigate('/Confirmation');
        } catch (error) {
            console.error('Error confirming order:', error);
            // Handle any potential errors
        }
    };

      
   
  return (
   
    <form onSubmit={cashOnDelivery} className='place-order'>
    
    <div className='place-order-left'>
    <div className='header'>
        <h1>Delivery Information</h1>
        <div className='multi-fields'>
            
<input required name='firstName' onChange={onChangeHandler} value={data.firstName} type='text' placeholder='First Name' />
<input required name='lastName' onChange={onChangeHandler} value={data.lastName} type='text' placeholder='Last Name' />
    </div>
    <input required name="email" onChange={onChangeHandler} value={data.email} type='text' placeholder='Email' />
   
    <div className='multi-fields'>
            
<input required name="street" onChange={onChangeHandler} value={data.street} type='text' placeholder='street address' />
<input required name="city" onChange={onChangeHandler} value={data.city} type='text' placeholder='City' />
    </div>
    <input required name="phone" onChange={onChangeHandler} value={data.phone} type='text' placeholder='Phone' />
    
    </div>
    <div className='deivery-btn-container'>
    <button type="submit"  className='delivery-btn'>Save</button> 
    <button type="button" onClick={handleCancel} className='delivery-btn'>Cancel</button>
    </div>
    </div>
    <div className='place-order-right'>
    <div className='cartitems-total'>
                    <h1>Cart Total</h1>
                   
                        <div className='cartitems-total-item'>
                            <p>Subtotal</p>
                            <p>{getTotalCartAmount()}</p>
                        </div>
                        <hr/>
                        <div className='cartitems-total-item'>
                            <p>Shipping Fee</p>
                            <p>Free</p>
                        </div>
                        <hr/>
                        <div className='cartitems-total-item'>
                            <h3>Total</h3>
                            <h3>{getTotalCartAmount()}</h3>
                        </div>
                      
                    </div>
                   
                    <button onClick={handleConfirmOrder} className='delivery-btn confirm'>Confirm</button>
    </div>
   
  
                    
                    
                    
    </form>

  )
}

export default CashondeliveryPage
