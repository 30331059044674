import React from 'react'
import "./Footer.css"
//import logo from "../Assets/logo-sm.png"
import instagram from "../Assets/instagram.png"
import facebook from "../Assets/facebook.png"
import whatsapp from "../Assets/whatsapp.png"
const Footer = () => {
  return (
    <div className='footer'>    
     
    <div className='footer-logo'>
    
     {/*<img src={logo} alt=""/>*/}
        <p>Syeda's Collection</p>
    </div>
    <ul className='footer-links'>
        <li>Company</li>
        <li>Products</li>
        <li>Offices</li>
        <li>About</li>
        <li>Contact</li>
    </ul>
    <div className='footer-social-icon'>
        <div className='footer-icons-container'>
         <a href="https://www.instagram.com/syedascollection_pk/"><img src={instagram} alt=""/></a>
        </div>
        <div className='footer-icons-container'>
        <a href="https://www.facebook.com/profile.php?id=61554774236468"><img src={facebook} alt=""/></a>
        </div>
        <div className='footer-icons-container'>
            <a href= "https://wa.me/92 331 3979959"><img src={whatsapp} alt=""/></a>
        </div>
    </div>
    <div className='footer-copyright'>
        <hr/>
        <p>Copyright @ 2024 - All Rights Reserved.</p>
    </div>
    </div>
  )
}

export default Footer
