import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import "./CartItems.css";
import { ShopContext } from '../../context/ShopContext';
import { FiMinus, FiPlus } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

const CartItems = () => {
    const { all_product, cartItems, removeFromCart, addToCart, getTotalCartAmount } = useContext(ShopContext);
    const navigate = useNavigate(); // Initialize useHistory

    const handleIncrement = (itemId) => {
        addToCart(itemId);
    };

    const handleDecrement = (itemId) => {
        removeFromCart(itemId);
    };

    const handleProceedToCheckout = () => {
        // Redirect to Cash on Delivery page
       navigate('/cash-on-delivery');
    };

    return (
        <div className='cartitems'>
            <div className='cartitems-format-main'>
                <p>Products</p>
                <p>Title</p>
                <p>Price</p>
                <p>Quantity</p>
                <p>Total</p>
                <p>Remove</p>
            </div>
            <hr />
            {all_product.map((product) => {
                if (cartItems[product.id] > 0) {
                    return (
                        <div key={product.id}>
                            <div className='cartitems-format'>
                                <img src={product.image} alt={product.name} className='carticon-product-icon' />
                                <p>{product.name}</p>
                                <p>{product.new_price}</p>
                                <div className="quantity-control">
                                    <button className='quantity-button' onClick={() => handleDecrement(product.id)}><FiMinus /></button>
                                    <span className="quantity">{cartItems[product.id]}</span>
                                    <button className='quantity-button' onClick={() => handleIncrement(product.id)}><FiPlus /></button>
                                </div>
                                <p>{product.new_price * cartItems[product.id]}</p>
                                <AiOutlineDelete onClick={() => { removeFromCart(product.id) }} className='delete-icon'/>
                            </div>
                        </div>
                    );
                }
                return null; // Return null if quantity is 0
            })}
            <div className='cartitems-down'>
                <div className='cartitems-total'>
                    <h1>Cart Total</h1>
                    <div>
                        <div className='cartitems-total-item'>
                            <p>Subtotal</p>
                            <p>{getTotalCartAmount()}</p>
                        </div>
                        <hr/>
                        <div className='cartitems-total-item'>
                            <p>Shipping Fee</p>
                            <p>Free</p>
                        </div>
                        <hr/>
                        <div className='cartitems-total-item'>
                            <h3>Total</h3>
                            <h3>{getTotalCartAmount()}</h3>
                        </div>
                    </div>
                    <button onClick={handleProceedToCheckout} className='btn-checkout'>Proceed to Checkout</button> {/* Add onClick event */}
                </div>
            </div>
        </div>
    );
}

export default CartItems;
