import React from 'react'
import "./Announcements.css"
const Announcements = () => {
  return (
    <div className='announcements'>
     <p className='sale blinking-text '> Flash Sale!!!Free Shipping on orders over 1500Rs</p>
    </div>
  )
}

export default Announcements
