import React from 'react'
import CartItems from '../components/CartItems/CartItems'
const Cart = () => {
  return (
    <div>
         <CartItems/>
    </div>
  )
}

export default Cart
