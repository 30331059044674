import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Css/Confirmation.css"
const Confirmation = () => {
  const navigate = useNavigate(); 
  const handleBackToOrder = () => {
    // Handle navigation to the order page or other actions
   navigate('/');
};

  // const [userOrders, setUserOrders] = useState([]);

  // useEffect(() => {
  //   fetchUserOrders();
  // }, []);

  // const fetchUserOrders = async () => {
  //   try {
  //     const token = localStorage.getItem('auth-token');
  //     const response = await fetch('/user-orders', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'auth-token': token,
  //       }
  //     });
  //     const data = await response.json();
  //     if (data.success) {
  //       setUserOrders(data.orders);
  //     } else {
  //       console.error('Failed to fetch user orders:', data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching user orders:', error);
  //   }
  // };

  return (
    <div className="confirmation-container">
        <h2 className="confirmation-title">Order Confirmed!</h2>
        <p className="confirmation-text">Thank you for your order.</p>
        <p className="confirmation-text">An email confirmation will be sent to you shortly.</p>
        <button className="confirmation-button" onClick={handleBackToOrder}>
            Back 
        </button>
    </div>
);
}

export default Confirmation;
