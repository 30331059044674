import React from 'react'
import "./Offers.css"
import bridalsetred from "../Assets/bridalsetred.png"
const Offers = () => {
  return (
    <div className='offers'>
      <div className='offers-left'>
        <h1>Exclusive</h1>
        <h1>Offers for You</h1>
        <p>Only on Best Sellers Products</p>
        <button>Check Now</button>
      </div>
      <div className='offers-right'>
        <img className='offer-img' src={bridalsetred} alt=""/>
      </div>
    </div>
  )
}

export default Offers
