import React, { useEffect, useState } from 'react'
import "../Popular/Popular.css"

import Item from '../item/Item'
const Popular = () => {
  const[popularProducts,setPopularProducts]=useState([]);
  useEffect(()=>{
    fetch('http://localhost:4000/trending')
    .then ((response)=>response.json())
    .then ((data)=>setPopularProducts(data));
  },[])
  return (

    <div className='popular'>
    <h1>Trending</h1>
    <hr/>
      <div className='popular-item'>
        {popularProducts.map((item,i)=>{
            return( <Item key={i} id={item.id}
             name={item.name} 
             image ={item.image} className="popular-image"
              new_price={item.new_price} 
              old_price={item.old_price}/>)
        })}
      </div>
    </div>
  )
}

export default Popular
