// Review.js
import React from 'react';
import './Reviews.css'; // Import CSS for Review component
import data from './data';

const Reviews = () => {
  return (
    <div className='reviews'>
    
    <h1 >Customer Reviews</h1>
    <hr/>
    <div className='review-container'>
    {data.map((review) => (
      <div className="review-card" key={review.id}>
        <h3>{review.name}</h3>
        <div className="rating">Rating: {review.rating}/5</div>
        <p>{review.text}</p>
      </div>
    ))}
  </div>
  </div>
  
  );
};

export default Reviews;
