// data.js
// data.js

const data = [
    {
      id: 1,
      name: "Ali Khan",
      rating: 4.5,
      text: "The jewelry I bought from here is stunning! It's beautifully crafted and looks amazing. Highly recommended!",
    },
    {
      id: 2,
      name: "Ayesha Ahmed",
      rating: 5,
      text: "I am in love with the jewelry collection! The designs are unique and elegant. Will definitely buy again.",
    },
    {
      id: 3,
      name: "Muhammad Hassan",
      rating: 4,
      text: "Great quality jewelry at affordable prices. The delivery was also quick. Very satisfied with my purchase.",
    },
    {
      id: 4,
      name: "Sana Zaidi",
      rating: 4.8,
      text: "Absolutely stunning! The jewelry pieces I received exceeded my expectations. Will recommend to all my friends.",
    },
    {
      id: 5,
      name: "Fatima Khan",
      rating: 4.2,
      text: "Beautiful craftsmanship and excellent customer service. I'm delighted with my purchase and will shop here again.",
    },
    {
        id: 6,
        name: "Ahmed Ali",
        rating: 4.3,
        text: "The jewelry quality is top-notch! I'm impressed with the attention to detail. Fast shipping too.",
      },
      {
        id: 7,
        name: "Saba Raza",
        rating: 4.7,
        text: "I absolutely adore my new jewelry pieces! They add a touch of elegance to any outfit. Great value for money.",
      },
      {
        id: 8,
        name: "Bilal Khan",
        rating: 4.6,
        text: "Fantastic jewelry collection with a wide range of designs to choose from. Excellent customer service as well.",
      },
      {
        id: 9,
        name: "Zainab Ali",
        rating: 4.9,
        text: "Exquisite craftsmanship and beautiful designs! Each piece is a work of art. Highly recommended for all jewelry lovers.",
      },
      {
        id: 10,
        name: "Farhan Ahmed",
        rating: 4.4,
        text: "I'm thrilled with my purchase! The jewelry arrived promptly and exceeded my expectations in terms of quality.",
      },
  ];
  
 
  
  
  export default data;
  